import { Board } from '@air/api/types';
import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { THUMBNAIL_PREFERENCES } from '~/constants/localStorageKeys';

export type ThumbnailPreference = 'contain' | 'cover' | 'default' | 'fill' | 'scale-down';

const THUMBNAIL_PREFERENCES_CLASS_NAME_MAP: { [key in ThumbnailPreference]: string } = {
  default: 'object-cover',
  contain: 'object-contain',
  cover: 'object-cover',
  fill: 'object-fill',
  'scale-down': 'object-scale-down',
};

export const getPreferenceClassName = (preference?: ThumbnailPreference) => {
  if (preference) {
    return THUMBNAIL_PREFERENCES_CLASS_NAME_MAP[preference];
  }

  return '';
};

export type ThumbnailPreferenceContextValue = {
  getPreference: (boardId?: string) => ThumbnailPreference | undefined;
  updatePreference: (boardId: string, value: ThumbnailPreference) => void;
};

export const ThumbnailPreferenceContext = createContext<ThumbnailPreferenceContextValue | null>(null);

export type ThumbnailPreferenceContextProviderProps = {
  children: ReactNode;
};

export const ThumbnailPreferenceContextProvider = ({ children }: ThumbnailPreferenceContextProviderProps) => {
  const [preferences, setPreferences] = useState<{ id: Board['id']; value: ThumbnailPreference }[]>([]);

  useEffect(() => {
    const update = () => {
      const preferences = localStorage.getItem(THUMBNAIL_PREFERENCES);

      if (preferences) {
        setPreferences(JSON.parse(preferences));
      }
    };

    window.addEventListener('storage', update);

    update();

    return () => {
      window.removeEventListener('storage', update);
    };
  }, []);

  const updatePreference = useCallback<ThumbnailPreferenceContextValue['updatePreference']>(
    (boardId, value) => {
      let newPreferences;

      if (preferences.find((preference) => preference.id === boardId)) {
        newPreferences = preferences.map((preference) => {
          if (preference.id === boardId) {
            return { ...preference, value };
          }

          return preference;
        });
      } else {
        newPreferences = [...preferences, { id: boardId, value }];
      }

      setPreferences(newPreferences);

      localStorage.setItem(THUMBNAIL_PREFERENCES, JSON.stringify(newPreferences));
    },
    [preferences],
  );

  const getPreference = useCallback<ThumbnailPreferenceContextValue['getPreference']>(
    (boardId) => {
      if (!boardId) return;

      return preferences?.find((preference) => preference.id === boardId)?.value || 'default';
    },
    [preferences],
  );

  const value = useMemo<ThumbnailPreferenceContextValue>(
    () => ({
      getPreference,
      updatePreference,
    }),
    [getPreference, updatePreference],
  );

  return <ThumbnailPreferenceContext.Provider value={value}>{children}</ThumbnailPreferenceContext.Provider>;
};

export const useThumbnailPreference = () => {
  const context = useContext(ThumbnailPreferenceContext);

  if (!context) {
    throw new Error('useThumbnailPreference must be used within ThumbnailPreferenceContextProvider');
  }

  return context;
};
