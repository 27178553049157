import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

export const MainContent = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'main'>>(
  ({ className, ...restOfProps }: ComponentPropsWithoutRef<'main'>, forwardedRef) => (
    <main
      ref={forwardedRef}
      className={tailwindMerge('relative flex h-[inherit] flex-1 flex-col overflow-y-auto', className)}
      {...restOfProps}
    />
  ),
);

MainContent.displayName = 'Main';
