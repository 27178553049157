import { parseAirDateToISO } from '@air/utilities';

export type SharePrivateContentLinkFooterProps = {
  createdAt: string;
  createdBy: string;
};

export const SharePrivateContentLinkFooter = ({ createdAt, createdBy }: SharePrivateContentLinkFooterProps) => {
  const formattedCreatedAt = new Date(parseAirDateToISO(createdAt)).toLocaleString();

  return (
    <div className="-mx-2 -mb-2 flex flex-col border-t border-t-grey-5 px-3.5 py-3 text-12 text-grey-9">
      {!!createdBy && <div>Link created by {createdBy}</div>}
      {!!formattedCreatedAt && <time dateTime={createdAt}>{formattedCreatedAt}</time>}
    </div>
  );
};
