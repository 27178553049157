import { formatBytes, formatDateVerbose } from '@air/utilities';
import { createSelector } from 'reselect';

import { PLACEHOLDER_IMAGE_BASE_NAME } from '~/components/ExportImagesModal/components/ExportImagesModalForm/components/ImageSuffixFormField';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { isPdfRenderedExtension } from '~/utils/ClipUtils';

import { AirState } from '../types';

const centralizedClipStateSelector = (state: AirState) => state.centralizedClip;

export const centralizedClipSelector = createSelector(centralizedClipStateSelector, ({ clip }) => clip);

export const centralizedClipIdSelector = createSelector(centralizedClipSelector, ({ id }) => id);

export const centralizedClipStatusSelector = createSelector(centralizedClipSelector, ({ status }) => status);

export const centralizedClipExtSelector = createSelector(centralizedClipSelector, ({ ext }) => ext);

export const centralizedClipAltResolutionsSelector = createSelector(
  centralizedClipSelector,
  ({ altResolutions }) => altResolutions,
);

export const centralizedClipDurationSelector = createSelector(centralizedClipSelector, ({ duration }) => duration);

export const centralizedDisplayTitleSelector = createSelector(
  centralizedClipSelector,
  ({ title, importedName }) => title || importedName || PLACEHOLDER_IMAGE_BASE_NAME,
);

export const centralizedClipDescriptionSelector = createSelector(
  centralizedClipSelector,
  ({ description }) => description,
);

export const centralizedClipIsDemoSelector = createSelector(centralizedClipSelector, ({ isDemo }) => isDemo);

export const centralizedClipSizeSelector = createSelector(centralizedClipSelector, ({ size }) => size);

export const centralizedClipFormattedSizeSelector = createSelector(centralizedClipSelector, ({ size }) =>
  formatBytes(size),
);

export const centralizedClipPDFAssetSelector = createSelector(centralizedClipSelector, ({ assets }) => assets.pdf);

export const centralizedClipPDFNumberOfPagesSelector = createSelector(
  centralizedClipSelector,
  ({ pdfPageNumber }) => pdfPageNumber,
);

export const centralizedClipDimensionsSelector = createSelector(centralizedClipSelector, ({ height, width }) => ({
  height,
  width,
}));

export const centralizedClipAssetsSelector = createSelector(centralizedClipSelector, ({ assets }) => assets);

export const centralizedClipVideoUrlSelector = createSelector(centralizedClipSelector, ({ assets }) => assets.video);

export const centralizedClipAssetIdSelector = createSelector(centralizedClipSelector, ({ assetId }) => assetId);

export const centralizedClipVTTUrlSelector = createSelector(
  centralizedClipSelector,
  ({ assets }) => assets.transcriptVtt,
);

export const centralizedClipSRTUrlSelector = createSelector(
  centralizedClipSelector,
  ({ assets }) => assets.transcriptSrt,
);

export const centralizedClipOpenDiscussionCountSelector = createSelector(
  centralizedClipSelector,
  ({ openDiscussionCount }) => openDiscussionCount,
);

export const centralizedClipOpenCommentCountSelector = createSelector(
  centralizedClipSelector,
  ({ openCommentCount }) => openCommentCount,
);

export const centralizedClipIsLoadingSelector = createSelector(centralizedClipSelector, ({ id }) => !id);

export const centralizedClipTypeSelector = createSelector(centralizedClipSelector, ({ type }) => type);

export const centralizedClipIsPDFTypeSelector = createSelector(centralizedClipExtSelector, (ext) =>
  isPdfRenderedExtension(ext),
);

export const centralizedClipRecordedAtSelector = createSelector(
  centralizedClipSelector,
  ({ recordedAt }) => recordedAt,
);

export const centralizedClipFormattedDimensionsSelector = createSelector(
  centralizedClipDimensionsSelector,
  (dimensions) => {
    return !!dimensions.width && !!dimensions.height ? `${dimensions.width} × ${dimensions.height}` : '';
  },
);

export const centralizedClipUpdatedAtSelector = createSelector(centralizedClipSelector, ({ updatedAt }) => updatedAt);

export const centralizedClipStatsSelector = createSelector(
  [centralizedClipFormattedDimensionsSelector, centralizedClipFormattedSizeSelector, centralizedClipUpdatedAtSelector],
  (dimensions, size, updatedAt) => {
    return `${formatDateVerbose(updatedAt)}${!!size ? ` · ${size}` : ''}${!!dimensions ? ` · ${dimensions}` : ''}`;
  },
);

export const centralizedClipKeywordsSelector = createSelector(centralizedClipSelector, ({ smartTags }) => smartTags);

export const centralizedClipTagsSelector = createSelector(centralizedClipSelector, ({ tags }) => tags);

export const centralizedClipCreatedDateSelector = createSelector(centralizedClipSelector, ({ createdAt }) => createdAt);

export const centralizedClipOwnerSelector = createSelector(centralizedClipSelector, ({ owner }) => owner);

export const centralizedClipSourceSelector = createSelector(centralizedClipSelector, ({ source }) => source);

export const centralizedClipBoardsSelector = createSelector(centralizedClipSelector, ({ boards }) => boards);

export const centralizedClipBoardInfoSelector = createSelector(
  [centralizedClipSelector, centralizedBoardSelector],
  ({ boards }, current) => {
    const currentBoard = boards.find((board) => board.id === current?.id);

    const others = !!current?.id ? boards.filter((board) => board.id !== current.id) : boards;

    return {
      others,
      current: !current?.id
        ? null
        : {
            ancestors: current.ancestors,
            id: current.id,
            library: current.library,
            openCommentCount: currentBoard?.openCommentCount,
            thumbnails: current.thumbnails,
            title: current.title,
          },
    };
  },
);

export const centralizedClipVersionCountSelector = createSelector(
  centralizedClipSelector,
  ({ assetVersionCount }) => assetVersionCount,
);

export const centralizedClipBookmarkedSelector = createSelector(
  centralizedClipSelector,
  ({ bookmarked }) => bookmarked,
);

export const centralizedClipVersionNumberSelector = createSelector(centralizedClipSelector, ({ version }) => version);

export const centralizedClipWorkspaceIdSelector = createSelector(
  centralizedClipSelector,
  ({ workspaceId }) => workspaceId,
);

export const centralizedClipLibraryCommentCountSelector = createSelector(
  centralizedClipSelector,
  ({ boards, openCommentCount }) => {
    let libraryCommentCount = openCommentCount ?? 0;

    boards.forEach((board) => {
      if (board.openCommentCount) {
        libraryCommentCount = libraryCommentCount - board.openCommentCount;
      }
    });

    return libraryCommentCount;
  },
);

// This is necessary for Cypress to mock methods, and must include all exported methods
export const CentralizedClipSelectors = {
  assetId: centralizedClipAssetIdSelector,
  clipId: centralizedClipIdSelector,
  displayTitle: centralizedDisplayTitleSelector,
};
