import classNames from 'classnames';
import { ComponentProps } from 'react';

import { UPLOAD_PANE_WIDTH_CLASSNAME } from '~/constants/WorkspaceSpacing';

export const PaneContainer = ({ className, style, ...rest }: ComponentProps<'div'>) => (
  <div
    className={classNames(
      'rounded-md bg-surface-1 shadow-[0px_1px_3px_0px_#00000026,0px_0px_2px_0px_#00000040,0px_2px_8px_0px_#00000033]',
      UPLOAD_PANE_WIDTH_CLASSNAME,
      className,
    )}
    style={{
      ...style,
      maxWidth: typeof window === 'undefined' ? 0 : window.innerWidth - 32,
    }}
    {...rest}
  />
);
