import { SubscriptionBillingCycle } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { Modal, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { RadioGroup, RadioGroupItem } from '@air/primitive-radio-group';
import { useAirModal } from '@air/provider-modal';
import { useToasts } from '@air/provider-toast';
import Dinero from 'dinero.js';
import Link from 'next/link';
import { useState } from 'react';

import { PlanInfoType } from '~/components/Modals/PlansModal/utils';
import { Routes } from '~/constants/routes';
import { UPGRADE_CONFIRMATION_MODAL, UPGRADE_CONFIRMATION_MODAL_BUTTON } from '~/constants/testIDs';
import { useBillingCycle } from '~/hooks/useBillingCycle';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateSubscription } from '~/swr-hooks/subscriptions/useUpdateSubscription';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { PlansModal } from './PlansModal/PlansModal';

export type UpgradePlanConfirmationModalProps = Pick<PlanInfoType, 'priceDetail' | 'prices' | 'stripePlanIds'>;

/**
 * This component (used as a modal) displays confirmation for plan upgrade. If a user confirms,
 * it calls updateSubscription method with selected planId, fetches new subscription, closes dialog and shows a toast
 */
const UpgradePlanConfirmationModal = ({
  priceDetail,
  prices,
  stripePlanIds,
  onClose,
}: AirModalProps<UpgradePlanConfirmationModalProps>) => {
  const { showToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [_showPlansModal, closePlansModal] = useAirModal(PlansModal);
  const { currentWorkspace } = useCurrentWorkspace();
  const { updateSubscription } = useUpdateSubscription();
  const [billingCycle, setBillingCycle] = useBillingCycle();

  const monthlyFormattedPrice = prices['monthly']
    ? Dinero({ amount: prices['monthly'] }).toFormat(prices['monthly'] % 100 ? '$0,0.00' : '$0,0')
    : 'Free';
  const annualFormattedPrice = prices['annual']
    ? Dinero({ amount: prices['annual'] }).toFormat(prices['annual'] % 100 ? '$0,0.00' : '$0,0')
    : 'Free';

  const stripePlanId = stripePlanIds[billingCycle];

  const purchasePlan = async () => {
    try {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      setIsLoading(true);

      if (stripePlanId) {
        await updateSubscription({ workspaceId, planId: stripePlanId });
      }

      setIsLoading(false);
      closePlansModal();
      onClose();
      showToast('Your purchase was successful');
    } catch (error) {
      reportErrorToBugsnag({
        error,
        context: 'Failed to upgrade plan',
        metadata: {
          data: {
            stripePlanId,
          },
        },
      });

      setIsLoading(false);
    }
  };

  return (
    <Modal dangerouslyBypassFocusLock data-testid={UPGRADE_CONFIRMATION_MODAL} isOpen onDismiss={onClose}>
      <header className="mb-4 flex flex-col gap-2">
        <ModalTitle>Upgrade now?</ModalTitle>
        <ModalDescription>
          Your next invoice will be automatically prorated to reflect your workspace&apos;s new subscription.
        </ModalDescription>
      </header>

      <div className="mb-2 text-16 font-semibold text-grey-12">Select your billing cycle</div>
      <RadioGroup value={billingCycle} onValueChange={(value) => setBillingCycle(value as SubscriptionBillingCycle)}>
        <RadioGroupItem align="start" id="monthly" value="monthly">
          <div className="text-16 font-medium text-grey-12">Monthly</div>
          <div className="text-14 font-normal text-grey-10">
            {monthlyFormattedPrice}
            {priceDetail ? ` ${priceDetail},` : ''} billed monthly
          </div>
        </RadioGroupItem>
        <RadioGroupItem align="start" id="annual" value="annual">
          <div className="text-16 font-medium text-grey-12">Annual - Save 17%</div>
          <div className="text-14 font-normal text-grey-10">
            {annualFormattedPrice}
            {priceDetail ? ` ${priceDetail}, ` : ''}billed annually
          </div>
        </RadioGroupItem>
      </RadioGroup>

      <div className="mt-4 text-14 text-grey-11">
        By confirming your subscription, you allow Air to charge your card for this payment and future payments in
        accordance with their{' '}
        <Link href={Routes.marketing.terms} target="_blank">
          terms
        </Link>
        .
      </div>

      <footer className="mt-8 flex justify-end gap-2">
        <Button appearance="ghost" color="grey" onClick={onClose} size="large">
          Cancel
        </Button>
        <Button
          data-testid={UPGRADE_CONFIRMATION_MODAL_BUTTON}
          appearance="filled"
          color="blue"
          isLoading={isLoading}
          onClick={purchasePlan}
          size="large"
        >
          Upgrade
        </Button>
      </footer>
    </Modal>
  );
};

export default UpgradePlanConfirmationModal;
