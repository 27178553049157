import { parseAirDateToISO } from '@air/utilities';
import classNames from 'classnames';
import { differenceInDays, format, startOfToday } from 'date-fns';
import pluralize from 'pluralize';
import { useMemo } from 'react';

interface SharePrivateContentExpirationDateDisplayProps {
  expirationDate: string;
  goToAdvancedSettings: () => void;
}

export const SharePrivateContentExpirationDateDisplay = ({
  expirationDate,
  goToAdvancedSettings,
}: SharePrivateContentExpirationDateDisplayProps) => {
  const date = parseAirDateToISO(expirationDate);
  const difference = differenceInDays(date, startOfToday());
  const text = useMemo(() => {
    if (difference < 0) return `Link expired on ${format(date, 'MMM d, yyyy')}.`;
    return `Link expires ${difference === 0 ? 'today.' : `in ${pluralize('day', difference, true)}.`}`;
  }, [difference, date]);

  const textColor = difference < 1 ? 'text-red-9' : difference === 1 ? 'text-blue-11' : 'text-grey-9';
  const hoverColor = difference < 1 ? 'hover:text-red-10' : difference === 1 ? 'hover:text-blue-12' : '';
  return (
    <p className={classNames('text-14', textColor, hoverColor)} role="alert">
      {text}
      <button
        onClick={goToAdvancedSettings}
        className={classNames('ml-1 font-semibold', textColor, difference > 1 ? 'hover:text-grey-12' : hoverColor)}
      >
        Manage expiration date &#8594;
      </button>
    </p>
  );
};
